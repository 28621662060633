import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import 'rsuite/dist/rsuite.min.css';
import Steps from 'rsuite/Steps';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useStateContext } from '../components/contexts/ContextProvider';
import AdminSidebar from '../components/sidebar/AdminSidebar';
import { useForm } from 'react-hook-form';
import jsPDF from 'jspdf';
import { FaShip, FaAnchor, FaDotCircle } from 'react-icons/fa';
import { LiaShipSolid } from 'react-icons/lia';

import 'jspdf-autotable';
import { GoDotFill } from 'react-icons/go';
import moment from 'moment';
import {
  CheckCircleFilled,
  EditOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import BarLoader from 'react-spinners/BarLoader';
import {
  addCategory,
  deleteCategory,
  getAllCategories,
  updateCategory,
} from '../components/functions/category';
import Navbar from '../components/Navbar';
import NavbarAdmin from '../components/nav/Navbar';
import {
  addContainerShippingDetails,
  addShippingBL,
  addShippingDetails,
  deleteShippingBl,
  getAllShippings,
  getBlDetailsById,
  getBlDetailsByIdTracking,
} from '../components/functions/shipping';
const cancelIcon = require('../assets/cancel-icon.png');

const BlTrackingDetails = () => {
  const location = useLocation();
  const { blId } = useParams();
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { activeMenu } = useStateContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    document.title = `SLREX - ${blId}`;
  }, [location]);

  const [editIsOpen, setEditIsOpen] = useState(false);

  const [showDataModal, setShowDataModal] = useState(false);
  const [showDataContainerModal, setShowDataContainerModal] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  const [selectedDataEdit, setSelectedDataEdit] = useState(null);

  const [data, setData] = useState([]);
  const [successData, setSuccessData] = useState('');
  const [errorData, setErrorData] = useState('');

  const loadCategories = () => {
    setLoading(true);
    setError('');
    setSuccess('');
    getAllCategories()
      .then((res) => {
        if (res === 'No categories found.') {
          setLoading(false);
          return setError('No categories found');
        }
        if (res.status === 'ok') {
          setCategories(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
        console.log(err);
      });
  };

  const loadData = () => {
    setLoading(true);
    setError('');
    setSuccess('');
    getBlDetailsByIdTracking(blId)
      .then((res) => {
        if (res === 'No bl number found.') {
          setLoading(false);
          return setError('No BL found');
        }
        if (res.status === 'ok') {
          setData(res.data);

          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    loadData();
    loadCategories();
  }, []);

  const closeModal = () => {
    setShowDataModal(false);
  };

  const closeContainerModal = () => {
    setShowDataContainerModal(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: registerData,
    formState: { errors: errorsData },
    handleSubmit: handleDataSubmit,
    reset: resetData,
  } = useForm({
    mode: 'onBlur',
  });

  const {
    register: registerContainerData,
    formState: { errors: errorsContainerData },
    handleSubmit: handleContainerDataSubmit,
    reset: resetContainerData,
  } = useForm({
    mode: 'onBlur',
  });

  const closeEditModal = () => {
    setEditIsOpen(false);
  };

  const handleEdit = (id) => {
    const dataFound = data.find((data) => data._id === id);

    setSelectedDataEdit(dataFound);
    setEditIsOpen(true);
  };

  useEffect(() => {
    if (selectedDataEdit) {
      setValue('name', selectedDataEdit.name);
    }
  }, [selectedDataEdit, setValue]);

  const blDetails = data && data.blDetails;

  const firstETD = blDetails && blDetails[0].etd;
  const lastETA = blDetails && blDetails[blDetails.length - 1].eta;

  const transitTimeDays = moment(lastETA, 'DD-MM-YYYY').diff(
    moment(firstETD, 'DD-MM-YYYY'),
    'days'
  );

  const downloadAsPDF = () => {
    const doc = new jsPDF();

    const headerTextLeft = 'UCFLINE ';
    const headerTextRight = `BL NO. - ${blId}`;
    const headerXLeft = 10;
    const headerXRight = doc.internal.pageSize.getWidth() - 15;
    const headerY = 10;

    // Set font styles
    doc.setFontSize(14);
    doc.setTextColor('#000000');
    doc.setFont('helvetica', 'bold');
    doc.text(headerTextLeft, headerXLeft, headerY, { align: 'left' });

    doc.setFontSize(14);
    doc.setTextColor('#072948');
    doc.setFont('helvetica', 'normal');
    doc.text(' LINE', headerXLeft + doc.getTextWidth(headerTextLeft), headerY, {
      align: 'left',
    });

    doc.setFontSize(14);
    doc.setTextColor('#000000');
    doc.setFont('helvetica', 'bold');
    doc.text(headerTextRight, headerXRight, headerY, { align: 'right' });

    const blHeaders = [
      'Country of Origin',
      'ETD',
      'Vessel Name',
      'Voyage Number',
      'Port of Loading',
      'Port of Discharge',
      'Place of Delivery',
      'Current Location',
      'ETA',
      'Status',
    ];

    const blData = data?.blDetails?.map((dts) => [
      dts.countryOfOrigin,
      dts.etd,
      dts.vesselName,
      dts.voyageNumber,
      dts.portOfLoading,
      dts.portOfDischarge,
      dts.placeOfDelivery,
      dts.currentLocation,
      dts.eta,
      dts.status,
    ]);

    const containerHeaders = [
      'Container Number',
      'Seal Number',
      'Category of Goods',
      'Weight',
      'Volume',
    ];

    const containerData = data?.containerDetails?.map((dts) => [
      dts.containerNumber,
      dts.sealNumber,
      dts.category?.name,
      dts.weight,
      dts.volume,
    ]);

    const columnWidths = [
      'auto',
      'auto',
      'auto',
      'auto',
      'auto',
      'auto',
      'auto',
      'auto',
      'auto',
      'auto',
    ];

    const tableStyle = {
      margin: { top: 20, left: 10 },
      headStyles: { fillColor: '#072948', textColor: '#FFFFFF' },
      styles: { overflow: 'linebreak', fontSize: 6 },
      columnStyles: {},
    };

    doc.autoTable({
      head: [blHeaders],
      body: blData,
      columnStyles: tableStyle.columnStyles,
      margin: tableStyle.margin,
      styles: tableStyle.styles,
      headStyles: tableStyle.headStyles,
      columnWidth: columnWidths,
      startY: 15,
    });

    doc.autoTable({
      head: [containerHeaders],
      body: containerData,
      columnStyles: tableStyle.columnStyles,
      margin: tableStyle.margin,
      styles: tableStyle.styles,
      headStyles: tableStyle.headStyles,
      columnWidth: columnWidths,
      startY: doc.autoTable.previous.finalY + 10,
    });

    const footerText =
      'This is a computer-generated tracking statement and does not require any signature.';
    const footerHeight = 10;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const textWidth =
      (doc.getStringUnitWidth(footerText) * doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const textHeight = doc.internal.getFontSize(); // Use the current font size as the text height
    const textX = (pageWidth - textWidth) / 2;
    const textY = pageHeight - footerHeight - 10;

    doc.setDrawColor('#ddd'); // Set the border color to gray (#ddd)
    doc.rect(textX, textY, textWidth, textHeight, 'D'); // Draw a border around the text area
    doc.setFontSize(8); // Set the font size to 8
    doc.text(footerText, textX + textWidth / 2, textY + textHeight / 2, {
      align: 'center',
      baseline: 'middle',
    }); // Center the text within the area

    doc.save(`SLREX-${blId}.pdf`);
  };

  return (
    <div className={'bg-main-bg dark:bg-main-dark-bg min-h-screen'}>
      <div className="m-2 md:p-5 bg-white rounded-1xl custom_design">
        {loading && (
          <div className="flex justify-center items-center">
            <BarLoader color="#072948" loading={loading} size={20} />
          </div>
        )}

        {data && data.length !== 0 ? (
          <div>
            {success && (
              <h5 className="text-center text_color_new auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
                <CheckCircleFilled
                  style={{
                    fontSize: '22px',
                    color: '#072948',
                    marginRight: '5px',
                    position: 'relative',
                    top: '3px',
                  }}
                />
                {success}
              </h5>
            )}
            {error && (
              <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '20px',
                    color: '#FAA0A0',
                    marginRight: '5px',
                    position: 'relative',
                    top: '3px',
                  }}
                />{' '}
                {error}
              </h5>
            )}

            <br />

            {editIsOpen && (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-[#072948] bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg sm:w-full md:w-3/5 pb-2 overflow-y-auto max-h-[100vh]">
                  <div className="bg-gray-100 border-b px-4 py-6 flex justify-between items-center rounded-lg">
                    <h3 className="font-semibold text-xl text-stone-600">
                      Voyage Details - {blId}
                    </h3>

                    <div className="flex">
                      <button
                        onClick={closeEditModal}
                        className="text-black close-modal"
                      >
                        <img src={cancelIcon} alt="Cancel" />
                      </button>
                    </div>
                  </div>

                  <div className="flex justify-center items-center my-3">
                    <div className="text-center text-sm md:text-xl border rounded-lg p-1 w-2/3 border-sky-500 flex justify-center items-center uppercase">
                      TRANSIT TIMES: {transitTimeDays} days
                    </div>
                  </div>

                  <div className="flex  p-0 md:p-10 justify-between items-center ">
                    <div className="p-6">
                      <div className="flex flex-col items-center w-full justify-start">
                        <Steps vertical current={-1}>
                          {blDetails.map((detail, index) => (
                            <Steps.Item
                              key={index}
                              title={`${detail.portOfLoading}`}
                              icon={
                                detail?.vesselStatus?.value === 'SEA PORT' ||
                                detail?.shipArrived ||
                                detail?.vesselStatus?.value === 'STOP OVER' ? (
                                  <LiaShipSolid
                                    style={{ color: '#000' }}
                                    size={35}
                                  />
                                ) : (
                                  <FaAnchor
                                    style={{ color: '#0BA5E9' }}
                                    size={30}
                                  />
                                )
                              }
                              description={
                                <div className="-mt-1 relative">
                                  <p className="font-bold text-black">
                                    ETD:{' '}
                                    {moment(detail?.etd, 'DD-MM-YYYY').format(
                                      'dddd, MMMM D, YYYY'
                                    )}
                                  </p>
                                  <div className="uppercase">
                                    Vessel Name: {detail?.vesselName}
                                  </div>
                                  <div className="uppercase">
                                    VOY NO: {detail?.voyageNumber}
                                  </div>
                                  <div>
                                    ETA:{' '}
                                    {moment(detail?.eta, 'DD-MM-YYYY').format(
                                      'dddd, MMMM D, YYYY'
                                    )}
                                    {detail?.vesselStatus?.value ===
                                      'HIGH SEA' && (
                                      <div
                                        className="absolute z-10 top-16"
                                        style={{
                                          left: -42,
                                          backgroundColor: '#fff',
                                        }}
                                      >
                                        <LiaShipSolid
                                          style={{ color: '#000' }}
                                          size={35}
                                        />
                                      </div>
                                    )}
                                  </div>

                                  <div className="uppercase">
                                    Country of Origin: {detail?.countryOfOrigin}
                                  </div>
                                  <div className="uppercase">
                                    Vessel status: {detail?.vesselStatus?.value}
                                  </div>

                                  <div className="uppercase md:flex justify-center items-center">
                                    Transshipment:{' '}
                                    <div
                                      className={`px-2 rounded-md md:ml-2 ${
                                        detail?.vesselStatus?.value ===
                                          'HIGH SEA' ||
                                        detail?.vesselStatus?.value ===
                                          'SEA PORT'
                                          ? 'bg-sky-500'
                                          : detail?.vesselStatus?.value ===
                                            'STOP OVER'
                                          ? 'bg-yellow-600'
                                          : detail?.vesselStatus?.value ===
                                            'ROLLED OVER'
                                          ? 'bg-red-500'
                                          : detail?.vesselStatus?.value ===
                                            'COMPLETE'
                                          ? 'bg-green-500'
                                          : 'bg-green-500'
                                      }`}
                                    >
                                      <span className="text-white">
                                        {detail?.transshipment}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              }
                            />
                          ))}
                        </Steps>
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={closeEditModal}
                    className=" text-green-500 font-bold py-2 px-10 rounded border-green-500 border float-right my-5 mr-3 md:mr-5"
                    disabled={loadingData}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}

            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 overflow-x-auto">
                <div className="pb-3 bg-white">
                  <div className="space-y-6">
                    <div className="flex justify-between items-center">
                      <p className="hidden md:block text-2xl">
                        BL NUMBER DETAILS
                      </p>

                      <div className="text-sm md:text-xl border p-1 border-sky-500 uppercase text-center rounded-lg">
                        TRANSIT TIMES: {transitTimeDays} days
                      </div>
                      <div className="flex justify-between items-center">
                        <button
                          onClick={() => setEditIsOpen(!editIsOpen)}
                          className="bg-sky-500 text-white rounded-md font-medium min-w-[120px] h-full px-4 py-2 mx-3"
                        >
                          Voyage Details
                        </button>
                        <button
                          onClick={downloadAsPDF}
                          className="bg_color_new text-white rounded-md font-medium min-w-[120px] h-full px-4 py-2"
                        >
                          <CloudDownloadOutlined
                            style={{
                              position: 'relative',
                              top: -3,
                            }}
                          />{' '}
                          PDF
                        </button>
                      </div>
                    </div>

                    <div className="overflow-scroll">
                      <table className="w-full">
                        <thead>
                          <tr className="">
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              BL NUMBER
                            </th>
                            <th className="py-2 px-6 text-left whitespace-nowrap">
                              {data?.blNumber}
                            </th>
                          </tr>
                          <tr>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Country of Origin
                            </th>

                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              ETD
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Vessel Name
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Voyage Number
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Port of Loading
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Port of Discharge
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Place of Delivery
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Current Location
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              ETA
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.blDetails?.map((dts, index) => {
                            const isEvenRow = index % 2 === 0;
                            const rowClass = isEvenRow
                              ? 'bg-gray-100'
                              : 'bg-white';

                            return (
                              <tr key={dts?._id} className={rowClass}>
                                <td className="py-3 text-gray-500">
                                  {dts?.countryOfOrigin}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.etd}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.vesselName}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.voyageNumber}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.portOfLoading}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.portOfDischarge}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.placeOfDelivery}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.currentLocation}
                                </td>
                                <td className="py-3 text-gray-500">
                                  {dts?.eta}
                                </td>

                                <td className="py-3 text-gray-500">
                                  {dts?.status}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="pb-3 mt-6 bg-white">
                  <div className="space-y-6">
                    <p className="text-2xl">BL NUMBER CONTAINER DETAILS</p>
                    <div className="overflow-scroll">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Container Number
                            </th>

                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Seal Number
                            </th>

                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Size
                            </th>

                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Category of Goods
                            </th>

                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Weight
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Volume
                            </th>
                            <th className="py-2 px-2 text-left text-sm text-white bg_color_new whitespace-nowrap">
                              Last Activity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.containerDetails?.map((dts, index) => {
                            const isEvenRow = index % 2 === 0;
                            const rowClass = isEvenRow
                              ? 'bg-gray-100'
                              : 'bg-white';

                            return (
                              <tr key={dts?._id} className={rowClass}>
                                <td className="py-3 text-gray-500 px-2">
                                  {dts?.containerNumber}
                                </td>
                                <td className="py-3 px-2 text-gray-500">
                                  {dts?.sealNumber}
                                </td>

                                <td className="py-3 px-2 text-gray-500">
                                  {dts?.size}
                                </td>

                                <td className="py-3 text-gray-500">
                                  {dts?.category?.name}
                                </td>

                                <td className="py-3 text-gray-500 px-2">
                                  {dts?.weight}
                                </td>
                                <td className="py-3 text-gray-500 px-2">
                                  {dts?.volume}
                                </td>

                                <td className="py-3 text-gray-500 px-2">
                                  {dts?.lastActivity}
                                </td>
                              </tr>
                            );
                          })}
                          <tr className="bg-gray-200">
                            <td
                              colSpan="4"
                              className="py-3 px-2 text-gray-500 font-bold"
                            >
                              Total Weight
                            </td>

                            <td className="py-3 text-gray-500 px-2 font-bold">
                              {data?.containerDetails
                                ?.reduce((acc, curr) => {
                                  const weight = parseFloat(curr?.weight || 0);
                                  return acc + weight;
                                }, 0)
                                .toFixed(2)}
                            </td>
                            <td className="py-3 text-gray-500 px-2"> </td>
                            <td className="py-3 text-gray-500 px-2"> </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <p className="my-5 text-right text_color_new">
                  <Link to="/" as={NavLink}>
                    Back to home
                  </Link>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-center text-gray-900 text-2xl">{blId}</p>
            {!loading && !data && (
              <div>
                <h5 className="text-center bold text-red-500 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
                  <ExclamationCircleOutlined
                    style={{
                      fontSize: '30px',
                      color: '#FAA0A0',
                      marginRight: '5px',
                      position: 'relative',
                      top: '3px',
                    }}
                  />{' '}
                  BL number does not exist
                </h5>
                <p className="text-gray-700 bold text-center">
                  Please note that the BL number is case-sensitive. Kindly
                  ensure you enter it correctly.
                </p>

                <div className="my-5 flex justify-center items-center align-middle">
                  <div className="notfound">
                    <Link to="/" as={NavLink}>
                      Go to home
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlTrackingDetails;
