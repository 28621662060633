import React from 'react'
import Laptop from '../assets/laptop.jpg'
import Single from '../assets/single.png'
import Double from '../assets/double.png'
import Triple from '../assets/triple.png'
import { FaUserTie } from 'react-icons/fa'
import { TbCloudComputing } from 'react-icons/tb'
import { PiShareNetworkBold } from 'react-icons/pi'
import { SiEquinixmetal } from 'react-icons/si'
import { GoContainer } from 'react-icons/go'
import { LiaShipSolid } from 'react-icons/lia'

const Services = () => {

  const backgroundUrl = `url('https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/service%20img/dwgnjsielf9493h0aa4o')`

  const serviceItems = [
      {
        id: 1,
        img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/service%20img/cxqjn1cmngpcbikxstzj',
        title: 'PREMIUM SERVICE',
        desc: 'We elevate conventional cargo shipping by providing comprehensive support to our clients, including customs documentation, clearance, commercial invoicing, and export licenses throughout the process.',
      },
      {
        id: 2,
        img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/service%20img/euq2yfucc3c151rm2gjp',
        title: 'EXPRESS SERVICE',
        desc: 'As a prominent figure in the container haulage industry, our company takes pride in its exceptional track record for on-time deliveries, unparalleled customer service, and unwavering dedication to upholding our commitments.',
      },
      {
        id: 3,
        img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/service%20img/b6mogl21zovrpo3vdz7m',
        title: 'CARGO SERVICE',
        desc: 'A UK-based cargo company specializing in comprehensive project cargo services, encompassing air and sea freight and road freight. Additionally, the company excels in managing global supply chain dynamics to ensure efficient and seamless logistics solutions.',
      },
      {
        id: 4,
        img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/service%20img/akdb7ufq02dlexnh2qfq',
        title: 'COURIER SERVICE',
        desc: 'UCF-Line provides a specialized express delivery service across the UK and Europe. They specialize in finding tailored solutions for delicate, valuable, and time-sensitive shipments. ',
      },
    
  ]
  return (
    <div className="w-full py-16 px-4 bg-fixed bg-cover bg-center overflow-hidden"
    style={{ backgroundImage: backgroundUrl }} id="service">
      <div className="max-w-[1240px] mx-auto mb-20">
        <div className="text-center">
        <h1 className="text-center text-2xl flex text-white flex-col items-center uppercase">
        Services
        <span className="relative mt-2 w-full max-w-[200px]">
          <span className="block bg-gray-200 w-full h-[1px]"></span>
          <span className="absolute left-1/2 transform -translate-x-1/2 bg-red-200 w-[50px] h-[4px] -top-[2px]"></span>
        </span>
      </h1>
      <p className="text-center text-white mt-4">
      Our proficiency encompasses various sectors, including automotive, retail, energy, healthcare, technology, industry, and aerospace.
      </p>
          
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto grid md:grid-cols-4 gap-4 ">

        {serviceItems && serviceItems?.map((item, i) => {
          return (
            <div key={item?.id} className="bg-white w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">

            <div className="flex justify-center items-center mb-1">
              <img src={item?.img} alt={item?.title} className='w-[170px] h-[170px]' />
            </div>
            <h2 className="text-xl font-bold text-center py-4">
            {item?.title}
            </h2>

            <div className="text-center font-medium">
              <p className="text-gray-600">
              {item?.desc}
              </p>
            </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Services
