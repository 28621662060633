import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { blogData } from '../data';

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogData.find((b) => b.id === parseInt(id));

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  return (
    <div className="container mx-auto px-4 py-8 min-h-[900px]">
      <img
        src={blog.image}
        alt={blog.title}
        className="w-full h-[500px] object-cover mb-4"
      />
      <h1 className="text-3xl font-bold mb-2">{blog.title}</h1>
      <p className="text-gray-500 mb-4">{blog.date}</p>
      {blog.description.map((para, index) => (
        <p key={index} className="mb-4 text-gray-700">
          {para}
        </p>
      ))}
    </div>
  );
};

export default BlogDetail;
