import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import Admin from './pages/Admin';
import Login from './pages/login';
import AdminRoute from './components/routes/AdminRoute';
import NotLoggedInRoute from './components/routes/NotLoggedInRoute';
import AdminCategories from './pages/AdminCategories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminShipping from './pages/AdminShipping';
import AdminUpdateShipping from './pages/AdminUpdateShipping';
import BlTrackingDetails from './pages/BlTrackingDetails';
import NotFound from './pages/NotFound/NotFound';
import News from './pages/News';
import Navbar from './components/Navbar';
import BlogDetail from './pages/BlogDetails';
import Footer from './components/Footer';
import Blog from './pages/Blog';

function App() {
  return (
    <>
      <ToastContainer />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/blogpage" element={<Blog />} />
        <Route exact path="/blog/:id" element={<BlogDetail />} />
        <Route exact path="/tracking/:blId" element={<BlTrackingDetails />} />

        <Route element={<NotLoggedInRoute />}>
          <Route exact path="/login" element={<Login />} />
        </Route>

        <Route element={<AdminRoute />}>
          <Route exact path="/admin/categories" element={<AdminCategories />} />
          <Route
            exact
            path="/admin/shipping/manage-bl"
            element={<AdminShipping />}
          />

          <Route
            exact
            path="/admin/shipping/manage-bl/update-bl/:blId"
            element={<AdminUpdateShipping />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
