import React from 'react'
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa'

const Footer = () => {
  return (
    <div className="w-full py-5 px-4 bg-slate-800 text-gray-300 text-center">
      <h1 className="text-center">
        © Copyright United Caribbean Freight Line. All Rights Reserved
      </h1>
    </div>
  )
}

export default Footer
