import React, { useState } from 'react'
import { LiaMapMarkedAltSolid } from 'react-icons/lia'
import { MdEmail } from 'react-icons/md'
import { PiPhone } from 'react-icons/pi'
import { useForm } from 'react-hook-form'
import { sendEmailContact } from './functions/user'
import BarLoader from 'react-spinners/BarLoader'
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons'

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [successData, setSuccessData] = useState('')
  const [errorData, setErrorData] = useState('')
  const [loadingData, setLoadingData] = useState(false)

  const onSubmit = (data) => {
    const newdata = {
      name: data.name,
      email: data.email,
      subject: data.subject,
      message: data.message,
    }
    setLoadingData(true)
    sendEmailContact(newdata)
      .then((res) => {
        if (res.status === 'ok') {
          setErrorData('')
          setSuccessData('Email sent successfully')
          setTimeout(() => {
            setSuccessData('')
            reset()
          }, 2000)
          setLoadingData(false)
        } else {
          setLoadingData(false)
          setErrorData(res)
        }
      })
      .catch((err) => {
        setLoadingData(false)
      })
  }
  return (
    <div className="w-full py-16 px-4 bg-white" id="contact">
      <div className="max-w-[1240px] mx-auto mb-10">
        <div className="text-center">
          <h3 className="text-3xl text-black">Contact Us</h3>
          <p className="my-5 text-md text-center text-black">
            Feel free to reach out to our dedicated team of experts who are
            ready to assist you with any inquiries or assistance you may need.
            Your satisfaction is our top priority, and we are here to provide
            exceptional support and guidance every step of the way. Contact us
            today and experience unparalleled service and professionalism.
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto">
        <div className="shadow-md p-4 md:col-span-2">
          <div className="flex justify-center items-center mb-1">
            <LiaMapMarkedAltSolid size={40} color="#072948" />
          </div>
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">Address</h2>
            <p className="text-gray-600 mb-4">
            14-16 Ocean Road, 
Southampton SO14 3GF,
United Kingdom

            </p>
          </div>

          <div className="map-container">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2516.712581170103!2d-1.400049123043809!3d50.8920297554708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487476cd3ae695b7%3A0x82988ddec51a5a57!2sOcean%20Rd%2C%20Southampton%2C%20UK!5e0!3m2!1sen!2sbd!4v1726114860047!5m2!1sen!2sbd"
              width="100%"
              loading="lazy"
              allowFullScreen
              className="map-iframe"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

            
          </div>
        </div>

        <div className="flex flex-col">
          <div className="shadow-md p-4">
            {/* Content for the second column */}
            <div className="flex justify-center items-center mb-1">
              <MdEmail size={40} color="#072948" />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-bold py-4 text-neutral-600">Email</h2>
              <p className="text-gray-600">info@ucfline.com</p>
            </div>
          </div>

          <div className="shadow-md p-4">
            {/* Content for the third column */}
            <div className="flex justify-center items-center mb-1">
              <MdEmail size={40} color="#072948" />
            </div>
            <div className="text-center">
              <h2 className="text-xl font-bold py-4 text-neutral-600">
                Tracking enquiry
              </h2>
              <p className="text-gray-600">tracking@ucfline.com</p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-[1240px] mx-auto mt-5">
        <div className="shadow-md p-4">
          <div className="text-center">
           
            <p className="text-gray-600 mb-4 font-sans">
              UNITED CARIBBEAN FREIGHT LINE LIMITED <br />
              14-16 Ocean Road, <br />
Southampton SO14 3GF, <br />
United Kingdom
 <br />
            </p>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            LIVERPOOL OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            3RD Floor, No.1 St Pauls Square, <br />
            Liverpool,  <br /> Merseyside, L3 9SJ

            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">Netherlands Office</h2>
            <p className="text-gray-600 mb-4 font-sans">
            Achterdijk 15-20, Rhoon Postbus 132, <br /> Hoogvliet 3190 AC <br />
ROTTERDAM. Netherlands

            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            BELGIUM OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            UCF-Line BELGIUM NV. <br />
KLIPPERSTRAAT 15 2030 ANTWERP. <br />
Belgium

            </p>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
            GERMANY OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            UCF-LINE (Germany) GmbH. <br />
Kleiner Burstah 07. 20461 Hamburg. <br />
Germany.


            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">SPAIN OFFICE</h2>
            <p className="text-gray-600 mb-4 font-sans">
            Ucf-Line, <br />
EDIFICIO AQUA, C/ de Menorca, 
19, Planta 11, 46023 <br />
Valencia, Spain


            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            China office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
UCF-Line <br />
200 Yan'an Rd (E), Huangpu,  <br />
Shanghai, China, 200002


            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            Singapore office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            UCF-Line SG, <br />
1 Paya Lebar Link, 
#13-01 Paya Lebar Quarter, <br />
Singapore



            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">Malaysia OFFICE</h2>
            <p className="text-gray-600 mb-4 font-sans">
            
UCF-Line MY <br />
Lorong Cempening, 42000 Port Klang, 
Selangor Darul Ehsan, <br />
Malaysia



            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            South Korea Office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            


South Korea, Busan, <br /> Jung-gu, 4가 <br />Jungang-daero, 83-5 STX



            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            Dubai office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
UCF-Line <br />
Burj – Al- Salam, 6th Street, <br /> Trade Center 1, Jumeirah, Dubai, <br /> Dubai Municipality.




            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">Hong Kong OFFICE</h2>
            <p className="text-gray-600 mb-4 font-sans">
            
            UCF-Line <br />
Kwun Tong, Wai Yip St, 223號, 
Manulife Financial Centre Tower B, 23, <br />
Hong Kong




            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            INDONESIA Office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            

           
International Financial Centre (IFC), Jl. Jenderal Sudirman No. <br />
Kav. 22-23 Tower 2, 41st Floor, Kuningan, Karet, 
Kecamatan Setiabudi, Kota Jakarta Selatan, <br />
Daerah Khusus Ibukota Jakarta 12920, Indonesia


            </p>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            INDIA OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
            Urmi Estate, A, Ganapatrao Kadam Marg, <br />
Nagin Nagar Chawl, Dhuru Wadi, Lower Parel, <br />
Mumbai, Maharashtra 400013, India


            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">BANGLADESH OFFICE</h2>
            <p className="text-gray-600 mb-4 font-sans">
            
VERTEX SHIPPING LINES, <br />
JIBAN BIMA BHABAN,
4TH FLOOR, <br />
56 AGRABAD, CHATTOGRAM





            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            Australlia Office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
     
 UCF-Line <br />
Level 6/44 Market St, <br />
Sydney NSW 2000, Australia


            </p>
          </div>
        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 max-w-[1240px] mx-auto mt-3">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            USA OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
            UCF-Line <br />
NEWPORT BEACH · 3501 Jamboree Road Suite 300, <br />
South Tower 92660 NEWPORT BEACH, CA, California



            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">USA - NEW YORK OFFICE</h2>
            <p className="text-gray-600 mb-4 font-sans">
            UCF-Line <br />
One Meadowlands Plaza Suite 1401 
07073 EAST RUTHERFORD, <br />
NJ. NEW YORK.






            </p>
          </div>
        </div>

        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600 uppercase">
            COLOMBIA Office
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            
     
        
Calle 93B No. 17 - 25, <br />
Oficina 501 Edificio Centro Internacional de Negocios 110221 <br />
BOGOTA, COLOMBIA



            </p>
          </div>
        </div>
      </div>

      

      <div className="max-w-[1240px] mx-auto mt-5">
        <div className="shadow-md p-4">
          <div className="text-center">
            <h2 className="text-xl font-bold py-1 text-neutral-600">
              BRANCH OFFICE
            </h2>
            <p className="text-gray-600 mb-4 font-sans">
            SOUTH AFRICA <br />
PANAMA <br />
VENEZUELA <br />
BRAZIL <br />
JAMAICA <br />

            </p>
          </div>
        </div>
      </div>

      <div className="w-full my-7">
        <form
          className="max-w-[1240px] mx-auto p-6 bg-white rounded-lg shadow-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          {loadingData && (
            <div className="flex justify-center items-center">
              <BarLoader color="#004a93" loading={loadingData} size={20} />
            </div>
          )}

          {successData && (
            <h5 className="text-center text-green-500 auth_error_success mt-3 text-success d-flex justify-content-center align-items-center">
              <CheckCircleFilled
                style={{
                  fontSize: '22px',
                  color: '#50C878',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
              />
              {successData}
            </h5>
          )}

          {errorData && (
            <h5 className="text-center text-red-400 auth_error_success my-3 text-danger d-flex justify-content-center align-items-center">
              <ExclamationCircleOutlined
                style={{
                  fontSize: '20px',
                  color: '#FAA0A0',
                  marginRight: '5px',
                  position: 'relative',
                  top: '3px',
                }}
              />{' '}
              {errorData}
            </h5>
          )}
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 text-gray-800">
              Name:
            </label>
            <input
              type="text"
              id="name"
              {...register('name', { required: 'Name is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.name && (
              <span className="text-red-500">{errors.name.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mb-2 text-gray-800">
              Email:
            </label>
            <input
              type="email"
              id="email"
              {...register('email', { required: 'Email is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.email && (
              <span className="text-red-500">{errors.email.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="subject" className="block mb-2 text-gray-800">
              Subject:
            </label>
            <input
              type="text"
              id="subject"
              {...register('subject', { required: 'Subject is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            />
            {errors.subject && (
              <span className="text-red-500">{errors.subject.message}</span>
            )}
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mb-2 text-gray-800">
              Message:
            </label>
            <textarea
              id="message"
              {...register('message', { required: 'Message is required' })}
              className="w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:border-indigo-500"
            ></textarea>
            {errors.message && (
              <span className="text-red-500">{errors.message.message}</span>
            )}
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 mt-4 font-semibold text-white bg_color_new rounded-md hover:bg-sky-700"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  )
}

export default Contact
