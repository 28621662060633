import React from 'react';
import secBg from '../assets/sec-bg.jpg';

const Features = () => {
  const backgroundUrl = `url(${secBg})`;

  const featuresContent = [
    {
      id: 1,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/icons/wv1qvhgylxdn8hheko6l',
      title: 'Economical Air Freight',
      desc: 'Our esteemed air cargo experts are pleased to offer a comprehensive range of efficient and dependable logistics solutions on a global scale.',
    },
    {
      id: 2,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/icons/gvirayxnlm2jkn2xm0k6',
      title: 'Warehousing',
      desc: 'Upon initial receipt, the warehouse staff carefully inspects the incoming goods to ensure that the correct quantity and type of products have been delivered.',
    },
    {
      id: 3,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/icons/grtlrfpt3nwojih2i9v5',
      title: 'Same day Delivery',
      desc: 'After an order is placed, it is immediately processed by our team. The items are carefully picked, packed, and prepared for shipment to ensure their safe delivery.',
    },
    {
      id: 4,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/icons/cy0vnqjsrdqvlhbgsvkh',
      title: 'Packaging & Storage',
      desc: 'We provide a variety of self-storage boxes including cardboard boxes in different sizes and shapes, and durable plastic storage boxes for long-term solutions. ',
    },
    {
      id: 5,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/icons/iu0xjrwqlqd7he2ncrd4',
      title: 'Time Bound Deliveries',
      desc: 'Time-bound deliveries are shipments that must arrive at their destination within a specific time frame or as quickly as possible.',
    },
    {
      id: 6,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/icons/nomalxosx3uptdk1ddjx',
      title: 'Multi-modal Transport',
      desc: 'Multimodal transport is the practice of moving goods using multiple modes of transportation, such as trucks, trains, ships, and planes, under a single contract.',
    },
  ];

  const featuresDown = [
    {
      id: 1,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/features-img/iixj91g953djqvngezdh',
      title: 'AIR FREIGHT',
      desc: 'UCF-Line Air Freight offers a seamless solution to elevate your supply chain operations to the skies. Our air transport services are designed to provide a safe and efficient means of conveying your time-sensitive and high-value cargo.',
    },
    {
      id: 2,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/features-img/gzubzbihxg3zpha5xaur',
      title: 'ROADWAY FREIGHT',
      desc: 'Our Road Freight Products guarantee top-quality road transportation, offering a range of services including Less-than-Truck-Load (LTL), Part-Truck-Load (PTL), and Full-Truck-Load (FTL) shipments. Additionally, we provide temperature-controlled and highly secured transports.',
    },
    {
      id: 3,
      img: 'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/v1/features-img/zxpnynzerzocq5ftptkz',
      title: 'OCEAN FREIGHT',
      desc: 'For a cost-effective and efficient mode of transportation for your cargo, look no further than sea freight or ocean transport. At UNITED CARIBBEAN FREIGHT LINE LIMITED, a leading global shipping company, we confidently offer a range of sea freight solutions for your cargo needs.',
    },
  ]
  return (
    <div className="w-full py-20 px-4">
      <h1 className="text-center text-2xl flex flex-col items-center">
        OUR FEATURES
        <span className="relative mt-2 w-full max-w-[200px]">
          <span className="block bg-gray-300 w-full h-[1px]"></span>
          <span className="absolute left-1/2 transform -translate-x-1/2 bg-red-500 w-[50px] h-[4px] -top-[2px]"></span>
        </span>
      </h1>
      <p className="text-center text-gray-600 mt-4">
        Empowering your supply chain with logistics services that add value at
        each and every stage.
      </p>

      <div className="my-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">

            {featuresContent && featuresContent?.map((item) => {
              return (
                <div className="flex items-start" key={item?.id}>
                  <div className='w-[70px] h-[40px] mr-1'>
                    <img
                      src={item?.img}
                      alt={item?.title}
                      className="w-[60px] h-[40px]"
                    />
                  </div>
                  <div className="ml-4">
                    <h2 className="text-xl font-bold">{item?.title}</h2>
                    <span className="block bg-red-500 w-[50px] h-[4px] my-2"></span>
                    <p className="text-gray-600 mt-2">
                      {item?.desc}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="w-full py-6 px-4 overflow-hidden">
        <div className="container mx-auto px-4 mt-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

            {featuresDown && featuresDown?.map((item, i) => {
              return (

                <div className="flex flex-col items-start text-left shadow-lg shadow-slate-300" key={item.id}>
                  <div className="w-full h-[250px] overflow-hidden mb-4 relative group">
                    <img
                      src={item?.img}
                      alt="Description"
                      className="w-full h-[250px] object-cover transform transition-transform duration-300 group-hover:scale-110"
                    />
                  </div>
                  <div className='p-4'>
                  <h2 className="text-lg font-bold mb-2">{item?.title}</h2>
                  <div className="relative w-full max-w-[200px] mb-2">
                    <span className="absolute left-0 bg-red-500 w-[50px] h-[4px] -top-[2px]"></span>
                  </div>
                  <p className="text-gray-600 pt-6">
                  {item?.desc}
                  </p>
                  </div>
                  
                </div>
              )
            })}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
