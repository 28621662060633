import React from 'react';

import Footer from '../components/Footer';

import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const News = () => {
  const navigation = useNavigate();
  return (
    <div className="">
      <h2 className="text-2xl text-center mt-4 md:text-4xl font-bold text-gray-900 mb-6">
        Home / News
      </h2>
      <div className="flex flex-col md:flex-row items-center justify-center mx-auto text-center mb-96">
        <div className="flex flex-col md:flex-row items-center justify-center mx-auto text-center px-4">
          <div className="max-w-4xl text-left">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
              Terminal Change in Alexandria, Egypt on EMED 2 Service
            </h2>

            <p className="text-lg text-gray-700 mb-4">
              In an ongoing effort to optimize service and meet the evolving
              needs of our clients, we are excited to announce a significant
              update to our operations in Alexandria. Starting from Week 39, the
              EMED 2 service will shift to a new terminal, enhancing
              connectivity and streamlining the transport network.
            </p>
            <p className="text-lg text-gray-700 mb-4">
              This loop will call Alexandria TMT from September 24th, 2024,
              allowing direct worldwide connections with Levant ports.
            </p>
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">
              EMED 2
            </h3>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>Fleet: 2 vessels x 800 TEU nominal capacity</li>
              <li>
                Rotation: Beirut - Limassol - Port Said East - Port Said West -
                Alexandria - Tripoli - Beirut - Tartous - Lattakia - Iskenderun
                - Beirut
              </li>
              <li>Frequency: Weekly</li>
              <li>
                Starting: ETA Beirut September 18th, 2024 with m/v "NICOLA"
              </li>
            </ul>
            <p className="text-lg text-gray-700">
              Please contact our sales teams for further information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
