import React, { useEffect, useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useStateContext } from '../../components/contexts/ContextProvider';
import UserProfile from './UserProfile';
import AdminSettings from './AdminSettings';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={() => customFunc()}
    style={{ color }}
    className="relative text-xl mr-6 rounded-full p-3 hover:bg-light-gray"
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />

    {icon}
  </button>
);

const NavbarAdmin = () => {
  const {
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
    setIsClicked,
  } = useStateContext();

  const { user } = useSelector((state) => ({ ...state }));
  const [allNotification, setAllNotification] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  const unRead = allNotification?.notifications?.reduce(
    (a, c) => (c.readOrUnread === false ? ++a : a),
    0
  );

  return (
    <div className="flex justify-between p-2 md:ml-6 md:mr-6">
      <NavButton
        title="Menu"
        customFunc={handleActiveMenu}
        icon={<AiOutlineMenu />}
      />
      <div className="flex">
        {!isClicked?.notification && unRead > 0 && (
          <div className="bg-red-500 w-5 h-5 flex justify-center items-center rounded-full relative -inset-x-11">
            <span className="text-white text-[11px]">
              {unRead > 20 ? `${unRead}+` : unRead}
            </span>
          </div>
        )}

        <div
          className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
          onClick={() => handleClick('userProfile')}
        >
          <p>
            <span className="text-gray-400 text-14">Hi,</span>{' '}
            <span className="text-gray-400 font-bold ml-1 text-14">
              {user?.business_name}
            </span>
          </p>
          <MdKeyboardArrowDown className="text-gray-400 text-14" />
        </div>

        {isClicked.userProfile && <UserProfile user={user} />}
      </div>
    </div>
  );
};

export default NavbarAdmin;
