import React, { useEffect, useState } from 'react';
import Typed from 'react-typed';
import { Link } from 'react-scroll';
import { IoIosArrowDown } from 'react-icons/io';
import logo from '../assets/logo.png';
import Tracking from './Tracking'; 

const Hero = () => {
  const images = [
    'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/huexs0fxfcqu9mefvnsg',
    'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/zqlrku64mudze4tqpoyx',
    'https://res.cloudinary.com/dwn02nfdv/image/upload/f_auto,q_auto/whui0ymbipv6xygcmo4f',
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(false); 

  useEffect(() => {
    const imageChangeInterval = setInterval(() => {
      setFade(true); 
      setTimeout(() => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(false); 
      }, 1000); 
    }, 5000);

    return () => clearInterval(imageChangeInterval);
  }, []);


  return (
    <div
    className={`text-white bg-fixed bg-cover bg-center overflow-hidden fade-background ${fade ? 'fade-out' : ''}`}
      style={{ backgroundImage: `url(${images[currentImageIndex]})` }}
      
    >
      <div className="flex flex-col md:flex-row items-center justify-center h-screen mx-auto text-center relative">
     
        <div className="flex flex-col justify-center items-center max-w-[800px] md:w-1/2 md:mr-4 md:relative md:block hidden">
          <div className="flex justify-center items-center">
            <img src={logo} alt="Logo" className="w-1/3" />
          </div>

          <h1 className="md:text-xl sm:text-xl text-xl font-bold md:py-6 uppercase">
            Welcome to United Caribbean Freight Line
          </h1>
          <div className="flex justify-center items-center">
            <p className="md:text-2xl sm:text-md text-xl font-bold py-4">
              Explore the world of shipping with us
            </p>
          </div>

          <Typed
            className="md:text-2xl sm:text-4xl text-xl font-bold md:pl-4 pl-2"
            strings={['Packaging', 'Deliveries', 'Shipments', 'Cargo']}
            typeSpeed={120}
            backSpeed={140}
            loop
          />

          <div className="flex justify-center items-center">
            <Link
              to="about"
              className="w-14 h-14 rounded-full flex items-center justify-center font-medium my-6 mx-auto py-3 text-white border-2 transition-colors duration-1000 hover:transform hover:translate-y-1 hover:text-white cursor-pointer"
              smooth={true}
              duration={300}
              offset={-70}
            >
              <IoIosArrowDown size={24} />
            </Link>
          </div>
        </div>

        {/* Tracking Section */}
        <div className="md:w-[30%] w-full bg-black md:bg-opacity-50 bg-opacity-0 p-4 rounded-lg">
          <Tracking />
        </div>
      </div>
    </div>
  );
};

export default Hero;
